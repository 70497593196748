import React from "react";
import "./home.css";

// Replace these URLs with the actual URLs provided by ImageKit for your videos
const video1Url = "https://ik.imagekit.io/zakariamasjid/videozakaria.mp4?updatedAt=1738432576232";


const HomePageView = () => {
  return (
    <>
      <div className="video-container" style={{backgroundColor:"#252a2c"}}>
        <h1 className="hwh1" style={{color:"white"}}>Relive the Moments: 2024 Event Highlights at Zakaria Masjid</h1>
        <div className="videocard" style={{}}>
          <video width="90%" height="400px" controls playsInline autoPlay muted>
            <source src={video1Url} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        
      </div>
    </>
  );
};

export default HomePageView;
